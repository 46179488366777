import { createGlobalStyle } from "styled-components";

import fontt from "../assets/fonts/Gruppo.ttf";
import fonttest from "../assets/fonts/Thunder-BoldLC.woff2";

const GlobalStyles = createGlobalStyle`
:root {
   --main: #1a3673;
   --bg: #eeeae6;
   --box: #647092;
   --text: #383c49;
   --text_fill: #b7bfd4;
}

@font-face {
   /* font-family: "Liquido-Regular"; */
   font-family: 'Thunder';
   src: url(${fonttest}) format("woff2"); 
   /* font-weight: normal;
   font-style: normal; */
   font-weight: bold;
   font-style: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
   font-family: "Gruppo";
   src: url(${fontt}) format("truetype");
   /* font-weight: normal;
   font-style: normal; */
}
*,
*:before,
*:after {
   box-sizing: inherit;
}
html {
}

body {
   /* font-family: "Liquido-Regular"; */
   font-family : "Thunder";
   text-transform :uppercase;
   padding: 0;
   margin: 0;
   overflow-x: hidden;
   background-color: var(--bg);
   
}

p {
   font-family: 'Cormorant Garamond', sans-serif;
   letter-spacing: 0.5px;
   margin: 0;
   padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
padding: 0;
   margin: 0;
   /* font-family: "Liquido-Regular"; */
   font-family : "Thunder";
   text-transform :  uppercase;
}
.center {
   text-align: center;
}

a {
   
   text-decoration: none;
}
/* For Scrollbar custom color for brand consistency */
.my-scrollable-element::-webkit-scrollbar-thumb {
   background-color: #888;
   border-radius: 5px;
}

.my-scrollable-element::-webkit-scrollbar-thumb:hover {
   background-color: #555;
}

.my-scrollable-element::-webkit-scrollbar-track {
   background-color: #f5f5f5;
}
body::-webkit-scrollbar {
   width: 12px;
}
html {
   scrollbar-width: thin;
   scrollbar-color: #fffff #9e978d;
}
body::-webkit-scrollbar-track {
   background: #fffff;
}
body::-webkit-scrollbar-thumb {
   background-color: #1a3673;
   border-radius: 6px;
}

/* Lazy loading secret Sauce */
.gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

img {
   max-width: 100%;
}

.inner-style {
   max-width: 1400px;
   /* remove this width if necessary */
   width: 100%;
   margin: 0 auto;
}
.margin {
   margin-top: 9rem;
}
.center-f {
   display: flex;
   justify-content: center;
   align-items: center;
}
.active  {
   text-decoration : none; 
 
   position  :  relative;
   transition: background-color 0.3s ease-in;
}
.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--bg);
  
  border-radius : 32px;
}


`;

export default GlobalStyles;
