import React from "react";

// const WaveStyles = styled.svg`
//    position: absolute;
//    top: -81px;
//    left: 0;
//    @media (min-width: 768px) {
//       top: -16rem;
//    }
// `;
export default function Waves() {
   return (
      <>
         <svg
            viewBox="0 0 1440 323"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=""
         >
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M0 20.9909L27 12.9907C53 5.99047 107 -8.00996 160 5.99047C213 20.9909 267 63.9922 320 113.994C373 164.995 427 221.997 480 236.997C533 250.998 587 221.997 640 200.996C693 178.996 747 164.995 800 149.995C853 135.994 907 120.994 960 84.9928C1013 48.9918 1067 -8.00995 1120 12.9907C1173 34.9913 1227 135.994 1280 149.995C1333 164.995 1387 92.9931 1413 56.992L1440 20.9909V323H1413C1387 323 1333 323 1280 323C1227 323 1173 323 1120 323C1067 323 1013 323 960 323C907 323 853 323 800 323C747 323 693 323 640 323C587 323 533 323 480 323C427 323 373 323 320 323C267 323 213 323 160 323C107 323 53 323 27 323H0V20.9909Z"
               fill="#1A3673"
            />
         </svg>
      </>
   );
}
