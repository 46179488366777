import { Link } from "gatsby";
import React, { useState } from "react";
import UseAnimations from "react-useanimations";
import menu4 from "react-useanimations/lib/menu4";
import styled from "styled-components";
import Logo from "../assets/images/brainwavesfinal.svg";

const NavStyles = styled.nav`
   position: sticky;
   top: 0;
   z-index: 100;
   display: flex;
   justify-content: space-around;
   align-items: center;
   height: 100px;
   width: 100%;
   padding: 0.5rem 0rem;
   background-color: var(--main);
   color: var(--bg);
   /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */

   .brand-name {
      text-decoration: none;
      color: black;
      font-size: 1.3rem;
      margin-left: 1rem;
   }

   .navigation-menu {
      /* margin-left: auto; */
   }

   .navigation-menu ul {
      text-transform: uppercase;
      display: flex;
      gap: 2rem;
      padding: 0;
   }

   .navigation-menu li {
      list-style-type: none;
      margin: 0 1rem;
   }
   .dot_t {
      position: relative;
   }
   .dot {
      position: absolute;
      top: -27px;
      left: 2rem;
      background-color: var(--bg);
      font-size: 1.5rem;
      min-width: 1.5rem;
      font-weight: 900;

      text-align: center;
      border-radius: 50%;
      color: var(--main);
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
   }

   .navigation-menu li a {
      text-decoration: none;
      display: block;
      width: 100%;
      color: var(--bg);
      font-size: 1.5rem;
      letter-spacing: 1.2px;
      font-weight: 900;
   }

   .hamburger {
      border: 0;
      height: 40px;
      width: 40px;
      padding: 0.5rem;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      display: none;
   }

   .hamburger:hover {
      /* background-color: #2642af; */
   }

   @media screen and (max-width: 768px) {
      .hamburger {
         display: block;
      }

      .navigation-menu ul {
         flex-direction: column;
         position: absolute;
         top: 100px;
         left: 0;
         width: 80%;
         height: calc(100vh - 80px);
         background-color: var(--main);
         /* color: var(--bg); */
         border-top: 1px solid black;
         display: none;
      }

      .navigation-menu li {
         text-align: center;
         margin: 0;
      }

      .navigation-menu li a {
         color: var(--bg);
         width: 100%;
         padding: 1.5rem 0;
      }

      .navigation-menu li:hover {
         /* background-color: var(--bg); */
         color: var(--main);
      }

      .navigation-menu.expanded ul {
         display: block;
      }
      .dot {
         display: none;
      }
   }
`;

export default function Navbar() {
   const [isMobile, setIsMobile] = useState(false);
   function handleButton() {
      setIsMobile(!isMobile);
   }
   return (
      <NavStyles
         className="navigation"
         data-aos="fade-down"
         data-aos-delay="400"
      >
         <Link to="/" className="brand-name">
            <img src={Logo} alt="Brain Waves Logo" width={150} height={100} />
         </Link>

         <button onClick={handleButton} className="hamburger">
            <UseAnimations animation={menu4} size={65} strokeColor="#ffffff" />
         </button>
         <div
            className={
               isMobile ? "navigation-menu expanded" : "navigation-menu"
            }
         >
            <ul>
               <li>
                  <Link to="/" activeClassName="active">
                     Home
                  </Link>
               </li>
               <li>
                  <a
                     href="https://my-store-cfa377.creator-spring.com/"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Shop
                  </a>
               </li>
               <li className="dot_t">
                  <span className="dot">5</span>
                  <Link to="/blog" activeClassName="active">
                     Blog
                  </Link>
               </li>

               <li className="dot_t">
                  <span className="dot">6</span>
                  <Link to="/category" activeClassName="active">
                     Audio
                  </Link>
               </li>
               <li>
                  <Link to="/about" activeClassName="active">
                     About
                  </Link>
               </li>
            </ul>
         </div>
      </NavStyles>
   );
}
