import React from "react";
import styled from "styled-components";
import Waves from "./Waves";
import Logo from "../assets/images/brainwavesfinal.svg";

import { Link } from "gatsby";
const FooterContainer = styled.footer`
   margin-top: -1rem;
   background-color: var(--main);
   padding: 2rem 0;
   /* padding-top : 2rem; */
   /* TOdo Remoeve this height */
   /* height: 100vh; */
   /* display: grid; */

   .wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      place-items: center;
      gap: 2rem;
   }
   .nav__links,
   .social__links {
      display: flex;
      flex-direction: column;
      a {
         font-family: "Cormorant Garamond", sans-serif;
         color: var(--bg);
         font-size: 1.5rem;
         transition: color 0.3s ease-in-out;
      }
      a:hover {
         color: var(--box);
      }
      h2 {
         color: var(--bg);
         letter-spacing: 0.2rem;
         font-size: 2.5rem;
         margin-bottom: 2rem;
      }
   }
   .copy {
      color: var(--bg);
      text-transform: uppercase;
      font-size: 1rem;
   }
   @media screen and (max-width: 768px) {
      .wrapper {
         grid-template-columns: 1fr;
         margin-bottom: 9rem;
      }
   }
`;
const ButtonStyles = styled.button`
   border-radius: 40px;
   border: none;
   background: var(--bg);
   padding: 1rem;
   font-family: "Gruppo";
   transition: all 0.3s ease-in-out;
   cursor: pointer;
   a {
      color: var(--main);
   }
   &:hover {
      background: var(--box);
      a {
         color: var(--bg);
      }
   }
`;

const Footer = () => {
   return (
      <>
         <Waves />
         <FooterContainer>
            <div className="wrapper">
               <div className="logo">
                  <img
                     src={Logo}
                     alt="Brain Waves logo"
                     // className="w-28 h-28"
                     width={200}
                     height={150}
                  />
               </div>
               <div className="nav__links">
                  <h2>Menu</h2>
                  <Link to="#">Home</Link>
                  <Link to="#">Shop</Link>
                  <Link to="#">Audio</Link>
                  <Link to="#">About Us</Link>
                  <Link to="#">Blog</Link>
               </div>

               <div className="social__links">
                  <h2>Connects</h2>
                  <Link to="">Facebook</Link>
                  <Link to="">Twitter</Link>
                  <Link to="">Instagram</Link>
                  <Link to="">TikTok</Link>
               </div>

               <p className="copy">@BrainWaves-SoundHealing</p>
               <ButtonStyles>
                  <a
                     href="https://www.facebook.com/brainwavessoundhealing"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Join Our Facebook Community
                  </a>
               </ButtonStyles>
               <p className="copy">
                  {" "}
                  Made By{" "}
                  <a
                     href="https://www.instagram.com/llinnovation231/"
                     target="_blank"
                     rel="noreferrer"
                  >
                     WE ARE
                  </a>
               </p>
            </div>
         </FooterContainer>
      </>
   );
};

export default Footer;
