import React from "react";
import Navbar from "./Nav";
import Footer from "./Footer";
import GlobalStyles from "../styles/GlobalStyles";
import ReactLenis from "@studio-freight/react-lenis";

export default function Layout({ children }) {
   return (
      <>
         <ReactLenis root>
            <GlobalStyles />
            <Navbar />
            {children}
            <Footer />
         </ReactLenis>
      </>
   );
}
